<template>
  <div class="row content-wrapper">
    <div class="col-md-12">
      <div class="tag-item tag-active">
        <span @click="onClickAll($event)">전체</span>
      </div>
      <div v-for="tag in hashtags" :key="tag.id" class="tag-item">
        <span @click="onClickTag(tag.id, $event)">#{{ tag.tag }}</span>
      </div>
      <hr />
    </div>
    <LoadingSpinner v-if="isLoading" />
    <template v-else>
      <div
        class="col-md-4 content-list-wrapper"
        v-for="spot in spots"
        :key="spot.id"
      >
        <div class="content-item-wrapper">
          <router-link :to="`/destination/${spot.id}`">
            <img
              :src="spot.thumbnail"
              :alt="spot.name"
              class="list-thumbnail"
            />
            <h4 class="spot-title">{{ spot.name }}</h4>
            <p class="spot-desc">{{ spot.summary }}</p>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" ref="paginate">
          <paginate
            :page-count="spotsLastPage"
            :click-handler="fetchMoreData"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-range="7"
            :prev-text="''"
            :next-text="''"
          >
          </paginate>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

export default {
  data() {
    return {
      totalPage: null,
      totalRows: 0,
      page: 1,
      isLoading: false,
      isActive: false,
      tagId: 0,
    };
  },
  computed: {
    ...mapState(['hashtags', 'spots', 'spotsLastPage']),
  },
  watch: {},
  components: {
    Paginate,
    LoadingSpinner,
  },
  created() {
    this.fetchData(this.page);
  },
  methods: {
    ...mapActions([
      'FETCH_ALL_HASHTAGS',
      'FETCH_SPOTS',
      'FETCH_SPOTS_WITH_TAG',
    ]),
    fetchData(page) {
      this.isLoading = true;
      this.FETCH_ALL_HASHTAGS();
      const payload = {
        id: 0,
        page: page,
      };
      this.FETCH_SPOTS_WITH_TAG(payload);
      this.isActive = true;
      this.isLoading = false;
    },
    async fetchMoreData(pageNum) {
      try {
        this.page = pageNum;
        const payload = {
          id: this.tagId,
          page: this.page,
        };
        await this.FETCH_SPOTS_WITH_TAG(payload);
        window.scrollTo(0, 0);
      } catch (err) {
        console.log(err);
      }
    },
    onClickAll(event) {
      try {
        this.isLoading = true;
        const payload = {
          id: 0,
          page: 1,
        };
        this.tagId = 0;
        this.page = 1;

        const target = event.currentTarget.parentElement;
        for (
          let i = 0;
          i < document.querySelectorAll('.tag-item').length;
          i++
        ) {
          const element = document.querySelectorAll('.tag-item')[i];
          element.classList.remove('tag-active');
        }
        target.classList.add('tag-active');

        this.FETCH_SPOTS_WITH_TAG(payload);

        for (
          let i = 0;
          i < document.querySelectorAll('.page-item').length;
          i++
        ) {
          const element = document.querySelectorAll('.page-item')[i];
          element.classList.remove('active');
        }
        document.querySelectorAll('.page-item')[0].classList.add('active');

        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async onClickTag(tag, event) {
      try {
        this.isLoading = true;
        this.tagId = tag;
        const payload = {
          id: tag,
          page: 1,
        };
        const target = event.currentTarget.parentElement;
        for (
          let i = 0;
          i < document.querySelectorAll('.tag-item').length;
          i++
        ) {
          const element = document.querySelectorAll('.tag-item')[i];
          element.classList.remove('tag-active');
        }
        target.classList.add('tag-active');

        await this.FETCH_SPOTS_WITH_TAG(payload);

        for (
          let i = 0;
          i < document.querySelectorAll('.page-item').length;
          i++
        ) {
          const element = document.querySelectorAll('.page-item')[i];
          element.classList.remove('active');
        }
        document.querySelectorAll('.page-item')[0].classList.add('active');
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.tag-item {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 5px 16px;
  border-radius: 66px;
  background-color: #44cac8;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}
.tag-active {
  background-color: #333;
}
.spot-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.spot-desc {
  margin-bottom: 0;
  font-size: 16px;
  color: #333;
  word-break: keep-all;
}
.content-item-wrapper {
  margin-bottom: 50px;
}
.list-thumbnail {
  border-radius: 5px;
}
</style>
