<template>
  <ListComponent />
</template>

<script>
import ListComponent from '@/components/hashtags/ListComponent.vue';

export default {
  components: {
    ListComponent,
  },
};
</script>

<style></style>
